<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="item-div">
        <div class="item-name-div">更新包类型：</div>
        <div class="item-value-div">{{versionMsg.type}}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">更新方式：</div>
        <div class="item-value-div">{{versionMsg.state}}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">版本号：</div>
        <div class="item-value-div">{{versionMsg.code}}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">版本名称：</div>
        <div class="item-value-div">{{versionMsg.name}}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">更新说明：</div>
        <div class="item-value-div">
          <label><textarea v-model="versionMsg.content" disabled></textarea></label>
        </div>
      </div>
      <div class="item-div">
        <div class="item-name-div">上架商店：</div>
        <div class="item-value-div">
          <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="1">应用宝</label>
          <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="2">小米</label>
          <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="3">华为</label>
          <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="4">oppo</label>
          <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="5">vivo</label>
        </div>
      </div>
      <div class="operate-div">
        <div class="text-c">
          <input class="save-input" type="button" value="保存" @click="onSave">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVersionInfo, postVersionEdit } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    versionId: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      versionMsg: {
        state: "",
        type: "",
        code: "",
        name: "",
        content: "",
        storeType: []
      },
      tipMsg: ""
    }
  },
  created() {
    getVersionInfo({id: this.versionId})
      .then(data => {
        this.versionMsg.state = this.getVersionState(data.state);
        this.versionMsg.type = this.getVersionType(data.type);
        this.versionMsg.code = data.versionCode;
        this.versionMsg.name = data.versionName;
        this.versionMsg.content = data.content;
        this.initStoreType(data.storeState);
      })
      .catch(error => {
        if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
          this.$layer.msg(error.data.msg);
        } else {
          this.$layer.msg("操作失败");
        }
        this.$layer.close(this.layerid);
      });
  },
  methods: {
    initStoreType(storeState) {
      if((storeState & 1) === 1){
        this.versionMsg.storeType.push("1");
      }
      if(((storeState >> 1) & 1) === 1){
        this.versionMsg.storeType.push("2");
      }
      if(((storeState >> 2) & 1) === 1){
        this.versionMsg.storeType.push("3");
      }
      if(((storeState >> 3) & 1) === 1){
        this.versionMsg.storeType.push("4");
      }
      if(((storeState >> 4) & 1) === 1){
        this.versionMsg.storeType.push("5");
      }
    },
    getVersionType(type) {
      if(type === 1){
        return "用户端";
      }else if(type === 2){
        return "商家端";
      }else if(type === 3){
        return "服务端";
      }else{
        return "其他";
      }
    },
    getVersionState(state) {
      if(state === 0){
        return "选择更新";
      }else if(state === 1){
        return "强制更新";
      }else{
        return "其他";
      }
    },
    onSave() {
      let formData = new FormData();
      formData.append("id", this.versionId);
      this.versionMsg.storeType.forEach(item => {
        formData.append("storeType", item);
      });

      postVersionEdit(formData)
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 100px;
        text-align: right;
      }
      .item-value-div {
        textarea {
          width: 282px;
          height: 80px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .store-type {
          margin-right: 20px;
          input {
            margin-right: 5px;
            vertical-align: middle;
          }
        }
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
    }
  }
}
</style>