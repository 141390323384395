<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <validation-observer ref="form">
        <div class="item-div">
          <div class="item-name-div">更新包类型：</div>
          <div class="item-value-div">
            <validation-provider rules="required" v-slot="{ errors }" name="versionType">
              <label>
                <select v-model="versionMsg.type">
                  <option value="">请选择更新包类型</option>
                  <option value="1">用户端</option>
                  <option value="2">商家端</option>
                  <option value="3">服务端</option>
                </select>
              </label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">更新方式：</div>
          <div class="item-value-div">
            <validation-provider rules="required" v-slot="{ errors }" name="updateType">
              <label>
                <select v-model="versionMsg.state">
                  <option value="">请选择更新包类型</option>
                  <option value="0">选择更新</option>
                  <option value="1">强制更新</option>
                </select>
              </label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">版本号：</div>
          <div class="item-value-div">
            <validation-provider rules="required|numeric" v-slot="{ errors }" name="versionCode">
              <label><input class="version-code-input" v-model="versionMsg.code" type="text" maxlength="9" placeholder="请输入版本号"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">版本名称：</div>
          <div class="item-value-div">
            <validation-provider rules="required|max:11" v-slot="{ errors }" name="versionName">
              <label><input class="version-name-input" v-model="versionMsg.name" type="text" maxlength="11" placeholder="请输入版本名称"></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
        <div class="item-div">
          <div class="item-name-div">更新说明：</div>
          <div class="item-value-div">
            <validation-provider rules="required|max:200" v-slot="{ errors }" name="versionContent">
              <label><textarea v-model="versionMsg.content" maxlength="200" placeholder="请输入更新说明"></textarea></label>
              <label class="err-tips">{{ errors[0] }}</label>
            </validation-provider>
          </div>
        </div>
<!--        <div class="item-div">-->
<!--          <div class="item-name-div">更新包：</div>-->
<!--          <div>-->
<!--            <input type="file" @change="onAddFile($event)">-->
<!--          </div>-->
<!--        </div>-->
        <div class="item-div">
          <div class="item-name-div">上架商店：</div>
          <div class="item-value-div">
            <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="1">应用宝</label>
            <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="2">小米</label>
            <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="3">华为</label>
            <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="4">oppo</label>
            <label class="store-type"><input type="checkbox" v-model="versionMsg.storeType" value="5">vivo</label>
          </div>
        </div>
      </validation-observer>
      <div class="operate-div">
<!--        <div class="text-c" v-show="uploadState > 0">已上传：{{uploadState}}%</div>-->
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input class="save-input" type="button" value="保存" @click="onSave" :disabled="this.cancelToken != null">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postVersionAdd, getCancelTokenSource } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      versionMsg: {
        state: "",
        type: "",
        code: "",
        name: "",
        content: "",
        storeType: []
      },
      apkFile: null,
      cancelToken: null,
      uploadState: 0,
      tipMsg: ""
    }
  },
  methods: {
    onAddFile(event) {
      if(event.target.files.length < 1){
        this.apkFile = null;
        return;
      }

      if(!/\.(apk)$/.test(event.target.files[0].name.toLowerCase())){
        event.target.value = "";
        this.apkFile = null;
        this.$layer.msg("只能上传apk格式文件");
        return;
      }

      this.apkFile = event.target.files[0];
    },
    onCancel() {
      if(this.cancelToken != null){
        this.cancelToken.cancel("Operation canceled by the user.");
      }
      this.$layer.close(this.layerid);
    },
    onSave() {
      // if(this.apkFile == null){
      //   this.setTipsMsg("请上传更新包")
      //   return;
      // }

      this.$refs.form.validate().then(res => {
        if(res){
          let formData = new FormData();
          formData.append("type", this.versionMsg.type);
          formData.append("state", this.versionMsg.state);
          formData.append("versionCode", this.versionMsg.code);
          formData.append("versionName", this.versionMsg.name);
          formData.append("content", this.versionMsg.content);
          this.versionMsg.storeType.forEach(item => {
            formData.append("storeType", item);
          });

          // formData.append("file", this.apkFile);
          this.cancelToken = getCancelTokenSource();
          postVersionAdd(formData, this.cancelToken.token, this.onUploadProgress)
            .then(() => {
              this.cancelToken = null;
              this.refreshData();
              this.$layer.close(this.layerid);
              this.$layer.msg("操作成功");
            })
            .catch(error => {
              this.cancelToken = null;
              if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
                this.$layer.msg(error.data.msg);
              } else {
                this.$layer.msg("操作失败");
              }
            });
        }
      });
    },
    onUploadProgress(progressEvent) {
      this.uploadState = `${progressEvent.total > 0 ? Math.floor(progressEvent.loaded / progressEvent.total * 100) : 0}`;
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
      .item-name-div {
        width: 100px;
        text-align: right;
      }
      .item-value-div {
        .version-code-input {
          width: 100px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        .version-name-input {
          width: 278px;
          height: 33px;
          padding: 0 5px;
          border: 1px solid #dcdcdc;
        }
        select {
          width: 160px;
          height: 35px;
          border: 1px solid #e5e5e5;
        }
        textarea {
          width: 282px;
          height: 80px;
          resize: none;
          padding: 3px;
          border: 1px solid #dcdcdc;
        }
        .store-type {
          margin-right: 20px;
          input {
            margin-right: 5px;
            vertical-align: middle;
          }
        }
        .err-tips {
          margin-left: 5px;
        }
      }
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        background: #4676c8;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>